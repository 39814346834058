<template>
  <div>
    <div v-if="role==='Agent' && levelOptions.length > 1" class="row">
      <div class="col-md-4">
        <b-form-group
          label="ระดับเอเย่นต์"
          label-class="text-primary"
        >
          <b-form-select
            v-model="data.account.levelId"
            :options="levelOptions"
          ></b-form-select>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <b-form-group
          label="ชื่อผู้ใช้ *"
          :description="`ความยาว ${minUsernameLength}-20 ตัวอักษร ภาษาอังกฤษ a-Z หรือตัวเลข 0-9 เท่านั้น`"
          :state="invalidKeys.username.status"
          :invalid-feedback="invalidKeys.username.text"
          label-class="text-primary"
        >
          <b-input-group
            :prepend="user.prepend"
          >
            <b-form-input
              v-model="user.username"
              type="text"
              placeholder="ชื่อผู้ใช้"
              required
              :state="invalidKeys.username.status"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-md-6">

        <b-form-group
          size="sm"
          label="รหัสผ่าน *"
          :state="invalidKeys.password.status && validPassword"
          label-class="text-primary"
        >
          <b-form-input
            v-model="data.user.password"
            type="password"
            placeholder="รหัสผ่าน"
            minLength="6"
            required
            :state="invalidKeys.password.status && validPassword"
          ></b-form-input>
          <small class="form-text text-muted">
            <ul class="pl-3 text-info">
              <li>ความยาวอย่างน้อย 8 ตัวอักษร <i class="fas fa-check text-success" v-if="pwLength"></i></li>
              <li>อักษรภาษาอังกฤษพิมพ์ใหญ่ อย่างน้อย 1 ตัว <i class="fas fa-check text-success" v-if="pwUppercase"></i></li>
              <li>อักษรภาษาอังกฤษพิมพ์เล็ก อย่างน้อย 1 ตัว <i class="fas fa-check text-success" v-if="pwLowercase"></i></li>
              <li>ตัวเลข อย่างน้อย 1 ตัว <i class="fas fa-check text-success" v-if="pwNumber"></i></li>
              <li>อักษรพิเศษ อย่างน้อย 1 ตัว <i class="fas fa-check text-success" v-if="pwSymbol"></i></li>
            </ul>
          </small>
        </b-form-group>
      </div>
      <!-- <div class="col-md-4">
        <b-form-group
          label="โทรศัพท์"
        >
          <b-form-input
            v-model="data.user.tel"
            type="text"
            placeholder="โทรศัพท์"
          ></b-form-input>
        </b-form-group>
      </div> -->
    </div>

    <div class="row">
      <div class="col-md-6">
        <b-form-group
          label="ชื่อ *"
          :state="invalidKeys.firstName.status"
          :invalid-feedback="invalidKeys.firstName.text"
          label-class="text-primary"
        >
          <b-form-input
            v-model="data.user.firstName"
            type="text"
            placeholder="ชื่อ"
            required
            :state="invalidKeys.firstName.status"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group
          label="นามสกุล"
          label-class="text-primary"
        >
          <b-form-input
            v-model="data.user.lastName"
            type="text"
            placeholder="นามสกุล"
          ></b-form-input>
        </b-form-group>
      </div>
      <!-- <div class="col-md-4">
        <b-form-group
          label="Line ID"
        >
          <b-form-input
            v-model="data.user.lineId"
            type="text"
            placeholder="Line ID"
          ></b-form-input>
        </b-form-group>
      </div> -->
    </div>

  </div>
</template>
<script>
import SystemConfigService from '@/services/SystemConfigService'
import { passwordStrength } from 'check-password-strength'

export default {
  name: 'NewAccountProfileForm',
  props: ['data', 'user', 'invalidKeys', 'role', 'minUsernameLength'],
  computed: {
    agentLevels() {
      return this.$store.state.accountLevels
    },
    levelOptions() {
      return this.agentLevels.map((l)=>{
        return {
          value: l._id,
          text: l.levelName
        }
      })
    },
    pwStrength() {
      return passwordStrength(this.data.user.password)
    },
    pwLength() {
      return this.pwStrength.length >= 8
    },
    pwLowercase() {
      return this.pwStrength.contains.includes('lowercase')
    },
    pwUppercase() {
      return this.pwStrength.contains.includes('uppercase')
    },
    pwNumber() {
      return this.pwStrength.contains.includes('number')
    },
    pwSymbol() {
      return this.pwStrength.contains.includes('symbol')
    },
    validPassword() {
      if(!this.data.user.password)
        return null

      return this.pwLength && this.pwLowercase && this.pwUppercase && this.pwNumber && this.pwSymbol
    }
  },
  watch: {
    agentLevels() {
      if(this.agentLevels.length > 0) {
        this.data.account.levelId = this.agentLevels[0]._id
      }
    }
  },
  mounted() {
    if(this.agentLevels.length > 0) {
      this.data.account.levelId = this.agentLevels[0]._id
    }
  }
}
</script>
